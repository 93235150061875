<template>
  <div>
    <div class="div_search_detail">
      <div class="div_search_show">
        <label class="table-title">未アクセス端末一覧</label>
      </div>
      <div class="div_search_memo">
        <div class="div-notice">
          <input
            type="checkbox"
            id="notice"
            v-on:click="search"
            checked="checked"
          />24時間以内
        </div>
        <div class="div-warning">
          <input
            type="checkbox"
            id="warning"
            v-on:click="search"
            checked="checked"
          />72時間以内
        </div>
        <div class="div-other">
          <input
            type="checkbox"
            id="other"
            v-on:click="search"
            checked="checked"
          />72時間以上
        </div>
        <p>検索結果：{{ totalCount }} 件</p>
        <div class="div-page-btn">
          <button class="prev" v-on:click="prevPage"></button>
          <p id="page"></p>
          <button class="next" v-on:click="nextPage"></button>
        </div>
      </div>
    </div>

    <div class="div_search_result title">
      <table>
        <thead>
          <tr>
            <th scope="col" class="client_id">
              <button id="sortClientId" class="sort" v-on:click="sortClientId">
                端末番号
              </button>
            </th>
            <th scope="col" class="store_nm">
              <button id="sortStoreNm" class="sort" v-on:click="sortStoreNm">
                店舗名
              </button>
            </th>
            <th scope="col" class="prod_nm">
              <button id="sortProdNm" class="sort" v-on:click="sortProdNm">
                商品グループ
              </button>
            </th>
            <th scope="col" class="last_access">
              <button
                id="sortLastAccess"
                class="sort"
                v-on:click="sortLastAccess"
              >
                最終アクセス日時 ▼
              </button>
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <div v-bind:class="'div_search_result items'">
      <table class="targetTable">
        <tbody>
          <tr v-for="row in prodsList" :key="row.clientId">
            <td class="client_id">{{ row.clientId }}</td>
            <td class="store_nm">{{ row.storeNm }}</td>
            <td class="prod_nm">{{ row.prodNm }}</td>
            <td
              class="last_access"
              v-bind:class="{ redColor: row.isRed, yellowColor: row.isYellow }"
            >
              {{ row.lastAccess }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="copyright"><p>ITX Corporation, Inc</p></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created: function () {
    console.log("Unaccessed is opend.");
    this.refreshToken();
    this.search();
  },
  data() {
    return {
      prodsList: [], //商品リスト
      totalCount: 0, // 検索結果
      currentPage: 1, // 現在ページ
      totalPage: 1, // 全ページ
      requestPage: 1, // 次遷移ページ
      sortItem: "lastAccess", // ソートを行う項目(サーバへ送るパラメータ)
      sortDirection: 1, // ソートの昇順・降順の指定(サーバへ送るパラメータ 0:ASC,1:DESC)
      sortFlgClientId: 0, // ソートフラグ(端末番号)
      sortFlgStoreNm: 0, // ソートフラグ(店舗名)
      sortFlgProdNm: 0, // ソートフラグ(商品グループ)
      sortFlgLastAccess: 1, // ソートフラグ(最終アクセス日時
      isScroll: false,
      isNotice: 1, // 24時間以内チェックボックスフラグ
      isWarning: 1, // 72時間以内チェックボックスフラグ
      isOther: 1, // 72時間以上チェックボックスフラグ
      isFirstTime: true,
      axiosFlg: 0, // 通信フラグ(連打対応)
    };
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  methods: {
    // トークンの繰り返し更新
    refreshToken() {
      const idToken = localStorage.getItem("idToken");
      const expiresIn = localStorage.getItem("expiresIn");
      const refreshIn = localStorage.getItem("refreshIn");

      if (!idToken || !expiresIn) {
        return;
      }

      // １度トークンを取得する
      this.getToken();

      // 2回目以降は間隔を置いて繰り返し取得
      console.log("expiresIn[ms]:" + expiresIn);
      console.log("refreshIn[ms]:" + refreshIn);

      var refreshTimer = setInterval(() => {
        let token = this.$store.getters.idToken;
        if (token == null) {
          clearInterval(refreshTimer);
        }

        let refreshTime = new Date();
        console.log("refresh time span[ms]:" + refreshTime);

        this.getToken();
      }, refreshIn);
    },
    // トークンの取得
    getToken() {
      axios
        .get("/refreshtoken", {
          headers: {
            Authorization: `Bearer ${this.idToken}`,
            isRefreshToken: true,
          },
        })
        .then((response2) => {
          if (response2.data.token) {
            const idToken2 = response2.data.token;
            const expiresIn2 = response2.data.expiresIn;
            const now = new Date();
            const expireTime = now.getTime() + expiresIn2;
            this.$store.commit("updateIdToken", idToken2);
            localStorage.setItem("idToken", idToken2);
            localStorage.setItem("expireTime", expireTime);
            console.log("expire time in :" + new Date(expireTime));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 再検索処理
    search() {
      console.log("Unaccessed's search begin.");

      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        if (
          this.isFirstTime ||
          (document.querySelector("#notice") != null &&
            document.querySelector("#notice").checked)
        ) {
          this.isNotice = 1;
        } else {
          this.isNotice = 0;
        }

        if (
          this.isFirstTime ||
          (document.querySelector("#warning") != null &&
            document.querySelector("#warning").checked)
        ) {
          this.isWarning = 1;
        } else {
          this.isWarning = 0;
        }

        if (
          this.isFirstTime ||
          (document.querySelector("#other") != null &&
            document.querySelector("#other").checked)
        ) {
          this.isOther = 1;
        } else {
          this.isOther = 0;
        }

        this.isFirstTime = false;

        const condition = {
          page: this.requestPage,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
          isNotice: this.isNotice,
          isWarning: this.isWarning,
          isOther: this.isOther,
        };

        axios
          .get(
            "/clientindicate?" +
              "page=" +
              encodeURIComponent(condition.page) +
              "&" +
              "sortItem=" +
              encodeURIComponent(condition.sortItem) +
              "&" +
              "sortDirection=" +
              encodeURIComponent(condition.sortDirection) +
              "&" +
              "isNotice=" +
              encodeURIComponent(condition.isNotice) +
              "&" +
              "isWarning=" +
              encodeURIComponent(condition.isWarning) +
              "&" +
              "isOther=" +
              encodeURIComponent(condition.isOther),
            {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            // console.log(`Bearer ${this.idToken}`);
            // console.log(response);

            // 正常処理の場合
            if (response.data.status == 0) {
              this.prodsList = response.data.data;
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;

              //テーブルが6行以下の場合は、下線を表示する
              document.querySelector(".targetTable").style["border-bottom"] =
                "hidden";

              if (this.prodsList != null) {
                if (this.prodsList.length < 7) {
                  document.querySelector(".targetTable").style[
                    "border-bottom"
                  ] = "dotted 1px";
                }
              }

              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo").style.visibility =
                "visible";

              document.querySelector("#page").innerText =
                this.currentPage + " / " + this.totalPage;
              document.querySelector("button.prev").innerText = "≪";
              document.querySelector("button.next").innerText = "≫";

              // ページ遷移ボタンの活性状態切り替え
              if (response.data.currentPage === 1) {
                document.querySelector("button.prev").disabled = true;
                document.querySelector("button.next").disabled = false;
                if (response.data.totalPage === 1) {
                  // 1/1の場合
                  document.querySelector("button.next").disabled = true;
                } else {
                  document.querySelector("button.prev").disabled = true;
                }
              } else if (
                response.data.currentPage === response.data.totalPage
              ) {
                document.querySelector("button.prev").disabled = false;
                document.querySelector("button.next").disabled = true;
              } else {
                document.querySelector("button.prev").disabled = false;
                document.querySelector("button.next").disabled = false;
              }

              // 最終アクセス日時が現在日時から24時間以内ものは黄色、24時間以降74時間以内の場合は赤色で表示
              var nowDate = new Date();
              var lastAccess;
              var lastAccessDate;
              var before24;
              var before72;
              before24 = new Date(
                nowDate.getFullYear(),
                nowDate.getMonth(),
                nowDate.getDate() - 1,
                nowDate.getHours(),
                nowDate.getMinutes()
              );
              before72 = new Date(
                nowDate.getFullYear(),
                nowDate.getMonth(),
                nowDate.getDate() - 3,
                nowDate.getHours(),
                nowDate.getMinutes()
              );
              for (var i = 0; i < this.prodsList.length; i++) {
                lastAccess = this.prodsList[i].lastAccess;
                lastAccessDate = new Date(
                  lastAccess.substring(0, 4),
                  parseInt(lastAccess.substring(5, 7)) - 1,
                  lastAccess.substring(8, 10),
                  lastAccess.substring(11, 13),
                  lastAccess.substring(14, 16)
                );

                if (lastAccessDate >= before24) {
                  this.prodsList[i].isYellow = true;
                } else if (lastAccessDate >= before72) {
                  this.prodsList[i].isRed = true;
                }
              }

              // スクロールバー表示有無
              if (this.prodsList.length > 7) {
                this.isScroll = true;
              } else {
                this.isScroll = false;
              }
            } else if (response.data.status == 1) {
              // 検索結果0件の場合
              document.querySelector("button.prev").innerText = "";
              document.querySelector("button.next").innerText = "";
              document.querySelector("#page").innerText = "";
              this.totalCount = 0;
              this.prodsList = [];
            }

            if (this.prodsList != null && this.prodsList.length > 0) {
              document.querySelector(".targetTable").style.width = "auto";
            } else {
              document.querySelector(".targetTable").style.width = "1203.5px";
            }
          })
          .catch((error) => {
            console.log(error);
            // エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            console.log(`Bearer ${this.idToken}`);
          })
          .finally(() => {
            console.log("CnfDelivery search End");
            this.axiosFlg = 0;
            var test = document.querySelector(".div_search_result.items");
            test.scrollTop = 0;
          });
      }
    },

    // ソート機能(端末番号)
    sortClientId() {
      if (this.prodsList != null && this.prodsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "clientId";
        // 昇順・降順の切替
        if (this.sortFlgClientId == 0) {
          this.sortDirection = 1;
          this.sortFlgClientId = 1;
          document.querySelector("#sortClientId").innerText = "端末番号 ▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgClientId = 0;
          document.querySelector("#sortClientId").innerText = "端末番号 ▲";
        }
        document.querySelector("#sortStoreNm").innerText = "店舗名";
        document.querySelector("#sortProdNm").innerText = "商品グループ";
        document.querySelector("#sortLastAccess").innerText =
          "最終アクセス日時";
        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // ソート機能(店舗名)
    sortStoreNm() {
      if (this.prodsList != null && this.prodsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "storeNm";
        // 昇順・降順の切替
        if (this.sortFlgStoreNm == 0) {
          this.sortDirection = 1;
          this.sortFlgStoreNm = 1;

          document.querySelector("#sortStoreNm").innerText = "店舗名 ▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgStoreNm = 0;
          document.querySelector("#sortStoreNm").innerText = "店舗名 ▲";
        }
        document.querySelector("#sortClientId").innerText = "端末番号";
        document.querySelector("#sortProdNm").innerText = "商品グループ";
        document.querySelector("#sortLastAccess").innerText =
          "最終アクセス日時";
        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // ソート機能(商品グループ)
    sortProdNm() {
      if (this.prodsList != null && this.prodsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "prodNm";
        // 昇順・降順の切替
        if (this.sortFlgProdNm == 0) {
          this.sortDirection = 1;
          this.sortFlgProdNm = 1;
          document.querySelector("#sortProdNm").innerText = "商品グループ ▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgProdNm = 0;
          document.querySelector("#sortProdNm").innerText = "商品グループ ▲";
        }
        document.querySelector("#sortClientId").innerText = "端末番号";
        document.querySelector("#sortStoreNm").innerText = "店舗名";
        document.querySelector("#sortLastAccess").innerText =
          "最終アクセス日時";
        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // ソート機能(最終アクセス日時)
    sortLastAccess() {
      if (this.prodsList != null && this.prodsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "lastAccess";
        // 昇順・降順の切替
        if (this.sortFlgLastAccess == 0) {
          this.sortDirection = 1;
          this.sortFlgLastAccess = 1;
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時 ▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgLastAccess = 0;
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時 ▲";
        }
        document.querySelector("#sortClientId").innerText = "端末番号";
        document.querySelector("#sortStoreNm").innerText = "店舗名";
        document.querySelector("#sortProdNm").innerText = "商品グループ";
        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // 前ボタン押下時処理
    prevPage() {
      if (this.currentPage > 1) {
        this.requestPage = this.currentPage - 1;
        this.search();
      }
    },

    // 次ボタン押下時処理
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.requestPage = this.currentPage + 1;
        this.search();
      }
    },
  },
};
</script>

<style scoped>
.div_search_show {
  display: flex;
  flex-basis: 80%;
  align-items: center;
  width: 200px;
  margin-right: 20px;
}

.div_search_detail {
  display: flex;
  margin-right: 20px;
  height: 120px;
}

.div_search_memo {
  position: relative;
  top: 30px;
  text-align: right;
  margin-left: 100px;
}

.div_search_memo p {
  margin: 5px 0 0 0;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  top: 4px;
  right: 4px;
}
.div-notice {
  display: flex;
  color: #a57010;
  font-size: 14px;
  margin-left: 10px;
}
.div-warning {
  display: flex;
  color: red;
  font-size: 14px;
  margin-left: 10px;
}
.div-other {
  display: flex;
  color: black;
  font-size: 14px;
  margin-left: 10px;
}
.div-page-btn {
  display: flex;
  justify-content: center;
}
button.prev {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
button.next {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

.div_search_result {
  position: relative;
  top: 30px;
}

.div_search_result.items {
  margin-top: -1px;
  height: 420px;
  overflow-y: auto;
  width: fit-content;
  border: solid 1px black;
}

.client_id {
  width: 200px;
}

td.client_id {
  border-left: hidden;
}

.store_nm {
  width: 330px;
}

.prod_nm {
  width: 430px;
}

.last_access {
  width: 200px;
}

td.last_access {
  border-right: hidden;
}

.targetTable {
  width: 1203px;
}

/* td {
  width: 240px;
  border-left: solid 1px black;
  border-right: solid 1px black;
}

th {
  height: 30px;
  border: solid 1px black;
} */

/* .items tr {
  height: 60px;
} */

/* .itemmax tr {
  height: 60px;
} */

button.sort {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 180px;
}

.redColor {
  color: red;
  border-color: black;
}

.yellowColor {
  color: #a57010;
  border-color: black;
}

button {
  font-size: 16px;
  height: 32px;
}

.copyright {
  margin-top: 35px;
  text-align: right;
}
</style>
